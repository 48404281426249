import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import AuthService from "../../../services/Admin/AuthService";
import common from "../../../utill/Common";
import { useLocation } from "react-router-dom";

const Menu = (props) => {
  const location = useLocation();
  const history = useHistory();

  const [navMenus, setNavMenus] = useState();
  const [userType, setUserType] = useState();
  const [name, setName] = useState();

  useEffect(() => {
    getDetails();
  });

  const getDetails = () => {
    const name = AuthService.getName();
    setName(name);

    const userType = AuthService.getUserType();
    setUserType(userType);

    const navMenus = AuthService.getNavMenus();
    setNavMenus(navMenus ? navMenus : []);
  };

  const company = props.props;

  const [activeMenu, setActiveMenu] = useState();

  const menuItems = [
    {
      title: "Dashboard",
      icon: "fas fa-tachometer-alt",
      link: "/admin/dashboard",
      subMenu: null,
    },
    {
      title: "Manage Users",
      icon: "fas fa-user",
      link: "#",
      subMenu: [
        {
          title: "Add User",
          link: "/admin/add_user",
          icon: "far fa-circle nav-icon",
        },
        {
          title: "User List",
          link: "/admin/user_list",
          icon: "far fa-circle nav-icon",
        },
      ],
    },
    {
      title: "Commercial Master",
      icon: "fas fa-receipt",
      link: "#",
      subMenu: [
        {
          title: "Manage Package",
          link: "/admin/package_list",
          icon: "far fa-circle nav-icon",
        },
        {
          title: "Set Commercial",
          link: "/admin/set_commercial",
          icon: "far fa-circle nav-icon",
        },
      ],
    },
    {
      title: "Recharge",
      icon: "fas fa-mobile",
      link: "#",
      subMenu: [
        {
          title: "Recharge Report",
          link: "/admin/recharge_report",
          icon: "far fa-circle nav-icon",
        },
        {
          title: "Live Recharge Report",
          link: "/admin/live_recharge_report",
          icon: "far fa-circle nav-icon",
          external: true,
        },
        {
          title: "Dispute Recharge Report",
          link: "/admin/dispute_recharge_report",
          icon: "far fa-circle nav-icon",
        },
        {
          title: "Manage Recharge API",
          link: "/admin/recharge_api_list",
          icon: "far fa-circle nav-icon",
        },
        {
          title: "Switch Recharge API",
          link: "/admin/switch_recharge_api",
          icon: "far fa-circle nav-icon",
        },
        {
          title: "Set Operator Code",
          link: "/admin/set_operator_code",
          icon: "far fa-circle nav-icon",
        },
        // {
        //   title: "Recharge",
        //   link: "#",
        //   icon: "fas fa-circle",
        //   subMenu: [
        //     {
        //       title: "Manage Recharge API",
        //       link: "/admin/recharge_api_list",
        //       icon: "far fa-circle nav-icon",
        //     },
        //     {
        //       title: "Switch Recharge API",
        //       link: "/admin/switch_recharge_api",
        //       icon: "far fa-circle nav-icon",
        //     },
        //     {
        //       title: "Set Operator Code",
        //       link: "/admin/set_operator_code",
        //       icon: "far fa-circle nav-icon",
        //     },
        //   ],
        // },
      ],
    },
    {
      title: "Prepaid Card",
      icon: "fas fa-credit-card",
      link: "#",
      subMenu: [
        {
          title: "User List",
          link: "/admin/ppi_user_list",
          icon: "far fa-circle nav-icon",
        },
        {
          title: "Card List",
          link: "/admin/prepaid_card_list",
          icon: "far fa-circle nav-icon",
        },
        {
          title: "Transactions",
          link: "/admin/prepaid_card_transactions",
          icon: "far fa-circle nav-icon",
        },
      ],
    },
    {
      title: "Lead Management",
      icon: "fas fa-poll-h",
      link: "#",
      subMenu: [
        {
          title: "Add Lead Product",
          link: "/admin/add_lead_product",
          icon: "far fa-circle nav-icon",
        },
        {
          title: "Lead Product List",
          link: "/admin/lead_product_list",
          icon: "far fa-circle nav-icon",
        },
      ],
    },
    {
      title: "Reports",
      icon: "fas fa-chart-bar",
      link: "#",
      subMenu: [
        {
          title: "NSDL PAN Card",
          link: "/admin/nsdl_pancard_report",
          icon: "far fa-circle nav-icon",
        },
        {
          title: "UTI PAN Card",
          link: "/admin/uti_pancard_report",
          icon: "far fa-circle nav-icon",
        },
        {
          title: "Insurance",
          link: "/admin/insurance_report",
          icon: "far fa-circle nav-icon",
        },
        {
          title: "Digit Commission Claim",
          link: "/admin/digit_commission_claim_report",
          icon: "far fa-circle nav-icon",
        },
        {
          title: "PG",
          link: "/admin/pg_report",
          icon: "far fa-circle nav-icon",
        },
        {
          title: "CP Summary",
          link: "/admin/commission_payment_summary",
          icon: "far fa-circle nav-icon",
        },
      ],
    },
    {
      title: "Wallet",
      icon: "fas fa-wallet",
      link: "#",
      subMenu: [
        {
          title: "Wallet Topup",
          link: "/admin/wallet_topup/null",
          icon: "far fa-circle nav-icon",
        },
        {
          title: "Wallet Topup History",
          link: "/admin/wallet_topup_history",
          icon: "far fa-circle nav-icon",
        },
        {
          title: "Transactions",
          link: "/admin/wallet_transactions",
          icon: "far fa-circle nav-icon",
        },
        {
          title: "Fund Request",
          link: "#",
          icon: "far fa-circle nav-icon",
          subMenu: [
            {
              title: "Pending Fund Request",
              link: "/admin/pending_fund_request",
              icon: "far fa-circle nav-icon",
            },
            {
              title: "Fund Request History",
              link: "/admin/fund_request_history",
              icon: "far fa-circle nav-icon",
            },
          ],
        },
      ],
    },
    {
      title: "Setup",
      icon: "fas fa-cog",
      link: "#",
      subMenu: [
        {
          title: "Manage Employee",
          link: "/admin/employee_list",
          icon: "far fa-circle nav-icon",
        },
        {
          title: "Settings",
          link: "/admin/settings",
          icon: "far fa-circle nav-icon",
        },
      ],
    },
    {
      title: "Logout",
      icon: "fas fa-sign-out-alt",
      link: "/admin/logout",
      subMenu: null,
    },
  ];

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 992;

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <a
          href="/#/user/dashboard"
          className="brand-link"
          style={{ textAlign: "center" }}
        >
          {/* {company && (
            <img
              src={company.logo}
              alt="Logo"
              className="brand-image"
              style={{ textAlign: "center" }}
            />
          )} */}
          <span>SNP PAY</span>
        </a>
        <div className="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">
          <div className="os-resize-observer-host observed">
            <div
              className="os-resize-observer"
              style={{ left: 0, right: "auto" }}
            />
          </div>
          <div
            className="os-size-auto-observer observed"
            style={{ height: "calc(100% + 1px)", float: "left" }}
          >
            <div className="os-resize-observer" />
          </div>
          <div
            className="os-content-glue"
            style={{ margin: "0px -8px", width: 249, height: 887 }}
          />
          <div className="os-padding">
            <div
              className="os-viewport os-viewport-native-scrollbars-invisible"
              style={{ overflowY: "scroll" }}
            >
              <div
                className="os-content"
                style={{ padding: "0px 8px", height: "100%", width: "100%" }}
              >
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                  <div className="image">
                    <img
                      src="/assets/img/user-icon.png"
                      className="img-circle elevation-2"
                      alt="User Image"
                    />
                  </div>
                  <div className="info">
                    <a href="/#/user/profile" className="d-block">
                      {name ? name : ""}
                      {/* {isMobile ? <p>true</p> : <p>false</p>} */}
                    </a>
                  </div>
                </div>

                <nav className="mt-2">
                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false"
                  >
                    {menuItems.map((item, index) => {
                      // if (
                      //   item.title == "User Module" &&
                      //   userType !== "Reseller"
                      // ) {
                      //   return null;
                      // }

                      // if (item.title == "Contact Us" && userType !== "User") {
                      //   return null;
                      // }

                      // if (item.title == "Settings" && userType !== "Reseller") {
                      //   return null;
                      // }

                      // if (
                      //   item.title == "Paysment" &&
                      //   common.isMaster() !== true
                      // ) {
                      //   return null;
                      // }

                      if (navMenus) {
                        const isFound = navMenus.filter(
                          (e) => e.title === item.title
                        );

                        //  console.log(isFound);

                        if (isFound[0]) {
                          if (isFound[0].isEnabled == false) {
                            return null;
                          }
                        }

                        if (!isFound) {
                          return null;
                        }
                      }

                      const isActive = location.pathname === item.link; // Check if the current route matches

                      let isMenuActive = [];

                      if (item.subMenu) {
                        isMenuActive = item.subMenu.filter(
                          (e) => e.link === location.pathname
                        );
                      }

                      if (item.subMenu) {
                        if (item.subMenu.subMenu) {
                          isMenuActive = item.subMenu.subMenu.filter(
                            (e) => e.link === location.pathname
                          );
                        }
                      }

                      return (
                        <li
                          key={index}
                          className={`nav-item ${
                            item.subMenu ? "has-treeview" : ""
                          }  ${
                            isMenuActive.length > 0
                              ? " menu-is-opening menu-open"
                              : ""
                          }`}
                        >
                          <Link
                            to={item.link}
                            className={`nav-link ${isActive ? "active" : ""} ${
                              isMenuActive.length > 0 ? "active" : ""
                            }`}
                            onClick={() => {
                              if (isMobile && item.link !== "#") {
                                document.body.setAttribute(
                                  "class",
                                  "sidebar-mini layout-fixed sidebar-closed sidebar-collapse"
                                );
                              }
                            }}
                          >
                            <i className={`nav-icon ${item.icon}`} />
                            <p>
                              {item.title}
                              {item.subMenu && (
                                <i className="fas fa-angle-left right" />
                              )}
                            </p>
                          </Link>
                          {item.subMenu && (
                            <ul className="nav nav-treeview">
                              {item.subMenu.map((subItem, subIndex) => {
                                const isSubActive =
                                  location.pathname === subItem.link; // Check for active sub-menu item

                                if (navMenus) {
                                  const isSubFound = navMenus.filter(
                                    (e) => e.title === item.title
                                  );

                                  //console.log(isSubFound);

                                  if (
                                    isSubFound &&
                                    isSubFound.subMenu != null
                                  ) {
                                    const isFound =
                                      isSubFound[0].subMenu.filter(
                                        (e) => e.title === subItem.title
                                      );
                                    //  console.log(isFound);
                                    if (isFound) {
                                      if (isFound[0].isEnabled == false) {
                                        return null;
                                      }
                                    }
                                    if (!isFound) {
                                      return null;
                                    }
                                  }
                                }

                                return (
                                  <li key={subIndex} className="nav-item">
                                    <Link
                                      to={subItem.link}
                                      className={`nav-link ${
                                        isSubActive ? "active" : ""
                                      }`}
                                      onClick={() => {
                                        if (isMobile && subItem.link !== "#") {
                                          document.body.setAttribute(
                                            "class",
                                            "sidebar-mini layout-fixed sidebar-closed sidebar-collapse"
                                          );
                                        }
                                      }}
                                    >
                                      <i
                                        className={`nav-icon ${subItem.icon}`}
                                      />
                                      <p>
                                        {subItem.title}
                                        {subItem.subMenu && (
                                          <i className="fas fa-angle-left right" />
                                        )}
                                      </p>
                                    </Link>
                                    {subItem.subMenu && (
                                      <ul className="nav nav-treeview">
                                        {subItem.subMenu.map(
                                          (subItem1, subIndex1) => {
                                            const isSubActive =
                                              location.pathname ===
                                              subItem1.link; // Check for active sub-menu item

                                            return (
                                              <li
                                                key={subIndex1}
                                                className="nav-item"
                                              >
                                                <Link
                                                  to={subItem1.link}
                                                  className={`nav-link ${
                                                    isSubActive ? "active" : ""
                                                  }`}
                                                  onClick={() => {
                                                    if (
                                                      isMobile &&
                                                      subItem1.link !== "#"
                                                    ) {
                                                      document.body.setAttribute(
                                                        "class",
                                                        "sidebar-mini layout-fixed sidebar-closed sidebar-collapse"
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <i
                                                    className={`nav-icon ${subItem1.icon}`}
                                                  />
                                                  <p>{subItem1.title}</p>
                                                </Link>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden">
            <div className="os-scrollbar-track">
              <div
                className="os-scrollbar-handle"
                style={{ width: "100%", transform: "translate(0px, 0px)" }}
              />
            </div>
          </div>
          <div className="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden">
            <div className="os-scrollbar-track">
              <div
                className="os-scrollbar-handle"
                style={{ height: "56.381%", transform: "translate(0px, 0px)" }}
              />
            </div>
          </div>
          <div className="os-scrollbar-corner" />
        </div>
      </aside>
    </div>
  );
};

export default Menu;
