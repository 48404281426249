import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import AuthService from "../../../services/User/AuthService";
import common from "../../../utill/Common";
import { useLocation } from "react-router-dom";

const Menu = (props) => {
  const location = useLocation();
  const history = useHistory();

  const [userType, setUserType] = useState();
  const [name, setName] = useState();

  useEffect(() => {
    getDetails();
  });

  const getDetails = () => {
    const name = AuthService.getName();
    setName(name);

    const userType = AuthService.getUserType();
    setUserType(userType);
  };

  const company = props.props;

  const [activeMenu, setActiveMenu] = useState();

  const menuItems = [
    {
      title: "Dashboard",
      icon: "fas fa-home",
      link: "/user/dashboard",
    },
    {
      title: "DB Module",
      icon: "fa fa-user-circle",
      link: "#",
      subMenu: [
        {
          title: "Add User",
          icon: "far fa-circle nav-icon",
          link: "/user/add_user",
        },
        {
          title: "User List",
          icon: "far fa-circle nav-icon",
          link: "/user/user_list",
        },
      ],
    },
    {
      title: "Commercial Master",
      icon: "fa fa-user-circle",
      link: "#",
      subMenu: [
        {
          title: "Add Package",
          icon: "far fa-circle nav-icon",
          link: "/user/add_package",
        },
        {
          title: "Package List",
          icon: "far fa-circle nav-icon",
          link: "/user/package_list",
        },
        {
          title: "Set Commercial",
          icon: "far fa-circle nav-icon",
          link: "/user/set_commercial",
        },
      ],
    },
    {
      title: "Services",
      icon: "fas fa-border-all",
      link: "#",
      subMenu: [
        {
          title: "Mobile Recharge",
          icon: "far fa-circle nav-icon",
          link: "/user/recharge/prepaid",
        },
        {
          title: "DTH Recharge",
          icon: "far fa-circle nav-icon",
          link: "/user/recharge/dth",
        },
        {
          title: "Google Play Recharge",
          icon: "far fa-circle nav-icon",
          link: "/user/recharge/google_play",
        },
      ],
    },
    {
      title: "Wallet",
      icon: "fas fa-wallet",
      link: "#",
      subMenu: [
        {
          title: "Add Money",
          icon: "far fa-circle nav-icon",
          link: "/user/add_money",
        },
        {
          title: "Wallet Statement",
          icon: "far fa-circle nav-icon",
          link: "/user/wallet_statement",
        },
        {
          title: "Wallet Transfer History",
          icon: "far fa-circle nav-icon",
          link: "/user/wallet_transfer_history",
        },
        {
          title: "Wallet Received History",
          icon: "far fa-circle nav-icon",
          link: "/user/wallet_received_history",
        },
      ],
    },
    {
      title: "Report",
      icon: "fas fa-server",
      link: "#",
      subMenu: [
        {
          title: "Recharge Report",
          icon: "far fa-circle nav-icon",
          link: "/user/recharge_report",
        },
        {
          title: "Bill Payment Report",
          icon: "far fa-circle nav-icon",
          link: "/user/bill_payment_report",
        },
        {
          title: "NSDL PAN Card Report",
          icon: "far fa-circle nav-icon",
          link: "/user/nsdl_pancard_report",
        },
      ],
    },
    {
      title: "Profile",
      icon: "fas fa-user-circle",
      link: "#",
      subMenu: [
        {
          title: "My Profile",
          icon: "far fa-circle nav-icon",
          link: "/user/my_profile",
        },
        {
          title: "My Commission",
          icon: "far fa-circle nav-icon",
          link: "/user/my_commission",
        },
        {
          title: "Change Password",
          icon: "far fa-circle nav-icon",
          link: "/user/change_password",
        },
        {
          title: "Reset PIN",
          icon: "far fa-circle nav-icon",
          link: "/user/reset_pin",
        },
      ],
    },
    {
      title: "Logout",
      icon: "fas fa-sign-out-alt",
      link: "/user/logout",
    },
  ];

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 992;

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <a
          href="/user/dashboard"
          className="brand-link"
          style={{ textAlign: "center" }}
        >
          <img
            src="/assets/img/white_logo.png"
            alt="Logo"
            className="brand-image"
            style={{ textAlign: "center", height: "200px" }}
          />

          {/* <span>SNP PAY</span> */}
        </a>
        <div className="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">
          <div className="os-resize-observer-host observed">
            <div
              className="os-resize-observer"
              style={{ left: 0, right: "auto" }}
            />
          </div>
          <div
            className="os-size-auto-observer observed"
            style={{ height: "calc(100% + 1px)", float: "left" }}
          >
            <div className="os-resize-observer" />
          </div>
          <div
            className="os-content-glue"
            style={{ margin: "0px -8px", width: 249, height: 887 }}
          />
          <div className="os-padding">
            <div
              className="os-viewport os-viewport-native-scrollbars-invisible"
              style={{ overflowY: "scroll" }}
            >
              <div
                className="os-content"
                style={{ padding: "0px 8px", height: "100%", width: "100%" }}
              >
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                  <div className="image">
                    <img
                      src="/assets/img/user-icon.png"
                      className="img-circle elevation-2"
                      alt="User Image"
                    />
                  </div>
                  <div className="info">
                    <a href="/user/profile" className="d-block">
                      {name ? name : ""}
                      {/* {isMobile ? <p>true</p> : <p>false</p>} */}
                    </a>
                  </div>
                </div>

                <nav className="mt-2">
                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false"
                  >
                    {menuItems.map((item, index) => {
                      if (item.title == "DB Module") {
                        if (
                          userType == "Reseller" ||
                          userType == "Master Reseller" ||
                          userType == "Distributor" ||
                          userType == "Master Distributor"
                        ) {
                        } else {
                          return null;
                        }
                      }

                      if (item.title == "Commercial Master") {
                        if (
                          userType == "Reseller" ||
                          userType == "Master Reseller"
                        ) {
                        } else {
                          return null;
                        }
                      }

                      // if (item.title == "Contact Us" && userType !== "User") {
                      //   return null;
                      // }

                      // if (item.title == "Settings" && userType !== "Reseller") {
                      //   return null;
                      // }

                      // if (
                      //   item.title == "Paysment" &&
                      //   common.isMaster() !== true
                      // ) {
                      //   return null;
                      // }

                      const isActive = location.pathname === item.link; // Check if the current route matches

                      let isMenuActive = [];

                      if (item.subMenu) {
                        isMenuActive = item.subMenu.filter(
                          (e) => e.link === location.pathname
                        );
                      }

                      return (
                        <li
                          key={index}
                          className={`nav-item ${
                            item.subMenu ? "has-treeview" : ""
                          }  ${
                            isMenuActive.length > 0
                              ? " menu-is-opening menu-open"
                              : ""
                          }`}
                        >
                          <Link
                            to={item.link}
                            className={`nav-link ${isActive ? "active" : ""} ${
                              isMenuActive.length > 0 ? "active" : ""
                            }`}
                            onClick={() => {
                              if (isMobile && item.link !== "#") {
                                document.body.setAttribute(
                                  "class",
                                  "sidebar-mini layout-fixed sidebar-closed sidebar-collapse"
                                );
                              }
                            }}
                          >
                            <i className={`nav-icon ${item.icon}`} />
                            <p>
                              {item.title}
                              {item.subMenu && (
                                <i className="fas fa-angle-left right" />
                              )}
                            </p>
                          </Link>
                          {item.subMenu && (
                            <ul className="nav nav-treeview">
                              {item.subMenu.map((subItem, subIndex) => {
                                const isSubActive =
                                  location.pathname === subItem.link; // Check for active sub-menu item

                                return (
                                  <li key={subIndex} className="nav-item">
                                    <Link
                                      to={subItem.link}
                                      className={`nav-link ${
                                        isSubActive ? "active" : ""
                                      }`}
                                      onClick={() => {
                                        if (isMobile && subItem.link !== "#") {
                                          document.body.setAttribute(
                                            "class",
                                            "sidebar-mini layout-fixed sidebar-closed sidebar-collapse"
                                          );
                                        }
                                      }}
                                    >
                                      <i className={subItem.icon} />
                                      <p>{subItem.title}</p>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden">
            <div className="os-scrollbar-track">
              <div
                className="os-scrollbar-handle"
                style={{ width: "100%", transform: "translate(0px, 0px)" }}
              />
            </div>
          </div>
          <div className="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden">
            <div className="os-scrollbar-track">
              <div
                className="os-scrollbar-handle"
                style={{ height: "56.381%", transform: "translate(0px, 0px)" }}
              />
            </div>
          </div>
          <div className="os-scrollbar-corner" />
        </div>
      </aside>
    </div>
  );
};

export default Menu;
