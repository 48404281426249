import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import upiqr from "upiqr";

import AuthService from "../../../../services/User/AuthService";
import WalletService from "../../../../services/User/WalletService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";
import QRCodeDialog from "./QRCodeDialog";

const Airpay = () => {
  const loadingCtx = useContext(LoadingContext);

  const [orderId, setOrderId] = useState();
  const [upiIntent, setUpiIntent] = useState();

  const formRef = useRef();
  const amountRef = useRef();

  const submitHandler = async () => {
    const amount = amountRef.current.value;

    if (!amount) {
      Swal.fire("Opps!", "Invalid Amount", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await WalletService.airpayCreateOrder(token, amount);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const orderId = responseData.data.order_id;
        const redirect_url = responseData.data.redirect_url;
        const upi_intent = responseData.data.upi_intent;

        if (!upi_intent || upi_intent == null) {
          window.open(redirect_url, "_blank");
        } else {
          setOrderId(orderId);
          setUpiIntent(upi_intent);
          setOpen(true);
        }

        formRef.current.reset();
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOrderId();
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div class="row">
        <div class="col-md-6 mx-auto">
          <div class="card-body">
            <form ref={formRef} autoComplete="off">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Amount"
                  ref={amountRef}
                />
              </div>

              <div class="text-center">
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={submitHandler}
                >
                  Add Money
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <QRCodeDialog
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        upiIntent={upiIntent}
        orderId={orderId}
        setOrderId={setOrderId}
        pgName="ARIPAY"
      />
    </React.Fragment>
  );
};

export default Airpay;
