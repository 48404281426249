import React, { useRef, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";

import Templete from "../../Templete/Templete";

import AuthService from "../../../../services/User/AuthService";
import NsdlPANCardService from "../../../../services/User/NsdlPANCardService";
import LoadingContext from "../../../../store/loading-context";
import HttpError from "../../../../utill/HttpError";
import { useHistory } from "react-router-dom";

const NsdlReceipt = () => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState();

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    var arr = window.location.href.split("?");
    var arr2 = arr[1].split("=");

    var authorization = arr2[1];

    loadingCtx.startLoading();

    try {
      //  const token = await AuthService.getAccessToken();

      const responseData = await NsdlPANCardService.nsdlResponse(authorization);

      const status = responseData.status;
      const message = responseData.message;

      // alert(JSON.stringify(responseData.data));

      if (status === "SUCCESS") {
        setData(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      {/* <Templete t1="Home" t2="NSDL Acknowledgement Receipt"> */}
      <section class="content">
        <div class="container-fluid">
          <div
            class="row justify-content-center align-items-center"
            style={{ height: "80vh" }}
          >
            <div class="col-md-12">
              <div class="card card-primary">
                <div class="card-header">
                  <h3 class="card-title">NSDL Acknowledgement Receipt</h3>
                </div>

                <div className="row justify-content-center mt-4">
                  <div className="col-sm-12">
                    <div class="row justify-content-center">
                      <div class="col-md-10">
                        <div class="card-body">
                          {data && (
                            <div>
                              <table class="table table-bordered table-hover">
                                <tbody>
                                  <tr>
                                    <td>STATUS</td>
                                    <td>:</td>
                                    <td>{data.status}</td>
                                  </tr>

                                  <tr>
                                    <td>RESPONSE</td>
                                    <td>:</td>
                                    <td>{data.error_message}</td>
                                  </tr>

                                  <tr>
                                    <td>ORDER ID</td>
                                    <td>:</td>
                                    <td>{data.order_id}</td>
                                  </tr>

                                  <tr>
                                    <td>ACKNOWLEDGEMENT NO</td>
                                    <td>:</td>
                                    <td>{data.ack_no}</td>
                                  </tr>

                                  <tr>
                                    <td>AMOUNT</td>
                                    <td>:</td>
                                    <td>{data.amount}</td>
                                  </tr>

                                  <tr>
                                    <td>TIMESTAMP</td>
                                    <td>:</td>
                                    <td>{data.date}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )}

                          <center>
                            <b style={{ color: "red" }}>
                              Please note that pending PAN applications are
                              automatically refunded at intervals of 3 to 4
                              hours.
                            </b>
                          </center>

                          <div className="text-center mt-4 mb-2">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={() => {
                                window.location.href = "/user/dashboard";
                              }}
                            >
                              Back to Dashboard
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* </Templete> */}
    </React.Fragment>
  );
};

export default NsdlReceipt;
